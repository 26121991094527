import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { GetDadosCorretora } from '../../api/DadosCorretora'
import { GETDADOSCORRETORA } from '../../util/typesReactQuery'
import useAlert from '../../hooks/useAlert';
import { Box } from '@mui/material';
import Subtitulo from '../Textos/Subtitulo';
import Titulo from '../Textos/Titulo';

export default function Component() {
      const [dadosCorretora, setDadosCorretora] = useState();
      const { Alert, showAlertError } = useAlert();

      useQuery(
            `GETDADOSCORRETORA`,
            () => GetDadosCorretora(),
            {
                  refetchOnWindowFocus: false,
                  onSuccess: (data) => {
                        if (!data || !data?.data) {
                              showAlertError(
                                    "Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: Nenhuma forma de pagamento encontrada - RXKP1 "
                              );
                              return;
                        }

                        if (data.errorMessage) {
                              showAlertError(
                                    `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - RG7ZB `
                              );
                              return;
                        }

                        if (data.status) setDadosCorretora(data.data);
                  },
                  onError: (error) => {
                        showAlertError(
                              `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  RI1K8`
                        );
                  },
            }
      );



      return (
            <>
                  <Alert />
                  <Box sx={{ padding: '2rem' }}>
                        <Titulo sx={{ fontSize: '1.2rem' }}>CNPJ:</Titulo>
                        <Subtitulo>{dadosCorretora?.cnpj}</Subtitulo>
                        <Titulo sx={{ fontSize: '1.2rem' }}>Razão Social:</Titulo>
                        <Subtitulo>{dadosCorretora?.razaoSocial}</Subtitulo>
                        <Titulo sx={{ fontSize: '1.2rem' }}>Endereço:</Titulo>
                        <Subtitulo>{dadosCorretora?.endereco}</Subtitulo>
                        {dadosCorretora?.contatos?.length > 0 &&
                              <>
                                    <Titulo sx={{ fontSize: '1.2rem' }}>Contatos:</Titulo>
                                    {dadosCorretora.contatos.map((item, index) => (
                                          <Fragment key={index}>
                                                <Subtitulo>{item.tipoContato.descricao} - {item.descricao}</Subtitulo>
                                          </Fragment>
                                    ))}
                              </>
                        }
                  </Box>

            </>
      );
}